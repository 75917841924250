<!--
 * @Description: 关键字搜索
 * @Author: 琢磨先生
 * @Date: 2022-11-01 08:45:00
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-10 17:45:09
-->

<template>
  <div v-power="'seller/v1/roomState/order/search'">
    <el-popover placement="bottom" :width="440" :visible="visibleSearchPopover">
      <template #reference>
        <el-input
          ref="input"
          v-model="q"
          placeholder="姓名、手机号、订单号、房间号、备注"
          clearable
          style="width: 440px"
          @input="inputChange"
          @focus="visibleSearchPopover = !model.is_empty"
          @blur="visibleSearchPopover = false"
        >
          <template #suffix>
            <el-icon @click="onSearch">
              <Search />
            </el-icon>
          </template>
        </el-input>
      </template>
      <div class="box room_state_key_search_box">
        <div class="tip">搜索结果为创建时间半年内的信息</div>
        <div class="body" v-loading="loading">
          <el-scrollbar>
            <div v-if="!loading && model.is_empty" style="text-align: center">没有找到匹配的结果，尝试其它关键词搜索</div>
            <div v-if="!model.is_empty">
              <div class="row name" v-if="model.name_list && model.name_list.length > 0">
                <div class="row_hd">姓名手机号</div>
                <div class="data">
                  <div
                    class="item"
                    @click="openOrderDtl(item)"
                    v-for="item in model.name_list"
                    :key="item.order_id"
                  >
                    <div class="icon">
                      <el-icon :size="22">
                        <Tickets />
                      </el-icon>
                    </div>
                    <div class="info">
                      <div class="title">
                        <span
                          class="v_html"
                          v-html="
                          item.contact_name.replace(
                            model.q,
                            `<span class='highlight'>${model.q}</span>`
                          )
                        "
                        ></span>
                        <span
                          v-if="item.mobile_phone"
                          class="v_html"
                          v-html="
                          item.mobile_phone.replace(
                            model.q,
                            `<span class='highlight'>${model.q}</span>`
                          )
                        "
                        ></span>
                        {{
                        item.mobile_phone_caller
                        ? `(${item.mobile_phone_caller})`
                        : ""
                        }}
                      </div>
                      <div class="order_no">单号：{{ item.order_no }}</div>
                      <div class="item_tip">
                        <div v-for="x in item.order_stores" :key="x">{{ x }}</div>
                      </div>
                    </div>
                    <div class="status">
                      <el-tag
                        v-if="item.status == 10"
                        type="warning"
                        size="small"
                      >{{ item.status_text }}</el-tag>
                      <el-tag v-else-if="item.status == 15" size="small">
                        {{
                        item.status_text
                        }}
                      </el-tag>
                      <el-tag type="info" v-else size="small">
                        {{
                        item.status_text
                        }}
                      </el-tag>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row order_no"
                v-if="model.order_no_list && model.order_no_list.length > 0"
              >
                <div class="row_hd">订单号</div>
                <div class="data">
                  <div
                    class="item"
                    @click="openOrderDtl(item)"
                    v-for="item in model.order_no_list"
                    :key="item.order_id"
                  >
                    <div class="icon">
                      <el-icon :size="22">
                        <Tickets />
                      </el-icon>
                    </div>
                    <div class="info">
                      <div class="title">
                        {{ item.contact_name }} {{ item.mobile_phone }}
                        {{
                        item.mobile_phone_caller
                        ? `(${item.mobile_phone_caller})`
                        : ""
                        }}
                      </div>
                      <div class="p">
                        单号：
                        <span
                          class="v_html"
                          v-html="
                          item.order_no.replace(
                            model.q,
                            `<span class='highlight'>${model.q}</span>`
                          )
                        "
                        ></span>
                      </div>
                      <div class="p item_tip">
                        <div v-for="x in item.order_stores" :key="x">{{ x }}</div>
                      </div>
                    </div>
                    <div class="status">
                      <el-tag
                        v-if="item.status == 10"
                        type="warning"
                        size="small"
                      >{{ item.status_text }}</el-tag>
                      <el-tag v-else-if="item.status == 15" size="small">
                        {{
                        item.status_text
                        }}
                      </el-tag>
                      <el-tag type="info" v-else size="small">
                        {{
                        item.status_text
                        }}
                      </el-tag>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row operate_remark"
                v-if="
                model.operate_remark_list &&
                model.operate_remark_list.length > 0
              "
              >
                <div class="row_hd">备注</div>
                <div class="data">
                  <div
                    class="item"
                    @click="openOrderDtl(item)"
                    v-for="item in model.operate_remark_list"
                    :key="item.order_id"
                  >
                    <div class="icon">
                      <el-icon :size="22">
                        <Tickets />
                      </el-icon>
                    </div>
                    <div class="info">
                      <div class="title">
                        {{ item.contact_name }} {{ item.mobile_phone }}
                        {{
                        item.mobile_phone_caller
                        ? `(${item.mobile_phone_caller})`
                        : ""
                        }}
                      </div>
                      <div class="p order_no">单号：{{ item.order_no }}</div>
                      <div class="p item_tip">
                        <div v-for="x in item.order_stores" :key="x">{{ x }}</div>
                      </div>
                      <div class="p">
                        备注：
                        <span
                          class="v_html"
                          v-html="
                          item.operate_remark.replace(
                            model.q,
                            `<span class='highlight'>${model.q}</span>`
                          )
                        "
                        ></span>
                      </div>
                    </div>
                    <div class="status">
                      <el-tag
                        v-if="item.status == 10"
                        type="warning"
                        size="small"
                      >{{ item.status_text }}</el-tag>
                      <el-tag v-else-if="item.status == 15" size="small">
                        {{
                        item.status_text
                        }}
                      </el-tag>
                      <el-tag type="info" v-else size="small">
                        {{
                        item.status_text
                        }}
                      </el-tag>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row house_no"
                v-if="model.house_no_list && model.house_no_list.length > 0"
              >
                <div class="row_hd">房间号</div>
                <div class="data">
                  <div
                    class="item"
                    @click="openOrderDtl(item)"
                    v-for="item in model.house_no_list"
                    :key="item.order_id"
                  >
                    <div class="icon">
                      <el-icon :size="22">
                        <House />
                      </el-icon>
                    </div>
                    <div class="info">
                      <div class="title">
                        {{ item.contact_name }} {{ item.mobile_phone }}
                        {{
                        item.mobile_phone_caller
                        ? `(${item.mobile_phone_caller})`
                        : ""
                        }}
                      </div>
                      <div class="p order_no">单号：{{ item.order_no }}</div>
                      <div class="p">
                        <span
                          class="v_html"
                          v-html="
                          item.tip.replace(
                            model.q,
                            `<span class='highlight'>${model.q}</span>`
                          )
                        "
                        ></span>
                      </div>
                    </div>
                    <div class="status">
                      <el-tag
                        v-if="item.status == 10"
                        type="warning"
                        size="small"
                      >{{ item.status_text }}</el-tag>
                      <el-tag v-else-if="item.status == 15" size="small">
                        {{
                        item.status_text
                        }}
                      </el-tag>
                      <el-tag type="info" v-else size="small">
                        {{
                        item.status_text
                        }}
                      </el-tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </el-popover>
    <order-dtl :order_id="current_order_id" v-if="current_order_id" @close="onClose"></order-dtl>
  </div>
</template>

<script>
import OrderDtl from "../../trade/order/order_dtl.vue";

export default {
  components: {
    OrderDtl,
  },
  data() {
    return {
      is_power: false,
      visibleSearchPopover: false,
      loading: false,
      q: "",
      //结果
      model: {},
      current_order_id: null,
    };
  },
  created() {
    // this.is_power = this.$power("seller/v1/roomState/order/search");
  },
  methods: {
    /**
     * 点击搜索图标
     */
    onSearch() {
      this.inputChange();
    },
    /**
     *
     */
    inputChange() {
      if (this.q) {
        this.visibleSearchPopover = true;
        this.loading = true;
        this.$http
          .get("seller/v1/roomState/order/search?q=" + this.q)
          .then((res) => {
            if (res.code == 0) {
              this.model = res.data;
              this.$refs.input.focus();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    /**
     * 打开详情
     */
    openOrderDtl(item) {
      this.current_order_id = item.order_id;
      this.visibleSearchPopover = false;
    },
    onClose() {
      this.current_order_id = null;
    },
  },
};
</script>

<style scoped>
.box .tip {
  color: #a8abb2;
  padding: 5px 0;
}
.box .body {
  padding: 5px 0;
  height: 400px;
}
.box .row .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 5px 0;
  cursor: pointer;
}

.box .row .item_tip {
  font-size: 12px;
}

.box .row .item .icon {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.box .row .item .info {
  flex-grow: 1;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}
.box .row .item .title {
  font-weight: 600;
}
.box .item .p {
  padding: 2px 0;
}
</style>

<style>
.room_state_key_search_box .v_html {
  margin-left: 5px;
}
.room_state_key_search_box .v_html:first-child {
  margin-left: 0;
}
.room_state_key_search_box .v_html .highlight {
  color: #409eff;
  font-weight: bold;
}
</style>